.contextMenu {
    overflow: auto;
    
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
        min-width: 200px;
        overflow: hidden;
        box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.12);
        border-radius: 12px;
        border: 1px solid #A3B0BB;
        background: #F9FAFB;
        padding: 0px;
        
        li {
            @apply text-blue font-medium;
            font-size: 14px;
            padding: 3px 10px;

            &:hover {
                background: #375c7a;
                color: #fff;
            }
        }
    }
    
    .MuiPopper-root {
        transform: translate3d(-44px, 100px, 0px) !important;
    }
}

.highlight-selected {
    padding: 5px 10px;
    background: #375c7a;
    color: #fff;

    &:hover {
        cursor: pointer;
    }
}

.option-select {
    padding: 5px 10px;
    border-bottom: 1px solid #e4f0ff;
    &:hover {
        background: #375c7a;
        color: #fff;
        cursor: pointer;
    }
}