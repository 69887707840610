.libraryMenuWrapper {
    @apply bg-blue;
    position: relative;
    width: 250px;
    min-width: 230px;
    min-height: 100vh;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
}

.libraryMenuWrapperFixed {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    width: 250px;
    position: fixed;
    padding: 20px 20px 30px 20px;
}

@media (max-width: 640px) {
    .libraryMenuWrapper {
        width: 100%;
        height: auto;
        min-height: auto;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
    }

    .libraryMenuWrapperFixed {
        width: 100%;
        height: auto;
        padding: 8px 10px;
        position: fixed;
        min-height: auto;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
        @apply bg-blue;
        z-index: 10;
    }
}