@import '../../variables.scss';

.content {
    background: #ECEFF1;
    padding: 20px;
    min-height: 100vh;
}

.libraryTable {
    margin-top: 50px;
    
        &__comment {
            overflow: hidden;
            max-width: 250px;
        }
    
        &__commentItem {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    
        &__generalInfo {
            padding: 25px 20px 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between; 
    }

    &__titleWrapper {
        @apply flex items-center text-blue cursor-pointer transition w-fit;

        &:hover {
            opacity: 0.7;
        }
    }

    &__titleIcon {
        @apply ml-3;
    } 

    &__navButtons {
        display: none;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
            box-shadow: none !important;
            padding-top: 20px;
            width: 100%;
            max-width: 1500px;
        
            th:first-child {
                padding: 10px 20px;
            }

            tbody {
        
                tr {
                    cursor: pointer;
                    &:hover {
                        background: #dae0e38d;
                    }
                }
            }

            td {
                padding: 5px 16px;
            }
    }

    input {
        outline: none;

        &:focus {
            border: 1px solid #4f81ab !important;
        }
    }
}


    .library {
         &__table {
        }
       
        &__itemsWrapper {
            display: none;
        }

        &__itemsSelectors--disabled {
            opacity: .5;
            pointer-events: none;
        }

        &__item {
            border: 1px solid #eee;
            border-radius: 6px;
            padding: 15px;
            font-weight: 500;
            display: flex;
            grid-gap: 30px;
            transition: .2s ease-in-out;
            cursor: pointer;
            margin-bottom: 20px;

            &:hover {
                border: 1px solid #e4e4e4;
                box-shadow: 0px 0px 10px #3070a447;
            }
        }

        &__item--active {
            @extend .library__item;
            border: 1px solid #2e404e7e;
            box-shadow: 0px 0px 10px #3070a447;

            &:hover {
                border: 1px solid #2e404e7e;
                box-shadow: 0px 0px 10px #3070a447;
            }
        }

        &__itemMainInfo {
            display: flex;
            display: flex;
            flex-wrap: wrap;
            grid-gap: 30px;
        }

        &__preview {
            width: 150px;
            height: 50px;
            object-fit: cover;
            object-position: left;
        }

        &__preview--open {
            width: 500px;
            height: 100px;
        }

    }


@media (max-width: 1100px) {
    .libraryTable {
        &__navButtons {
            display: flex;
            
            img {
                &:hover {
                    transition: .2s ease-in-out;
                    opacity: 0.7;
                }
            }
        }
    }
        .library {
        &__itemsWrapper {
            display: flex;
            grid-gap: 30px;
            padding: 20px;
            flex-wrap: wrap;
            
                .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
                    color: #23425C !important;
                    font-weight: 500;
                }
            
                .MuiOutlinedInput-notchedOutline {
                    border: 1px solid #eee !important;
                    border-radius: 30px;
                }
            
                .MuiSvgIcon-root {
                    color: #23425C !important;
                }
            }
            
            &__table {
                display: none; 
        }
    }
}


@media (max-width: 640px) {
    .libraryTable {
        padding: 10px 0;
        margin-top: 80px;

        &__generalInfo {
            padding: 0px 10px 10px 10px;
        }
    }

    .library {
        &__itemsWrapper {
            padding: 0;
            grid-gap: 16px;

            .MuiOutlinedInput-notchedOutline {
                border: 1.5px solid #fff !important;
                // border-radius: 30px;
            }
        
        }

        &__item {
            background: #fff;
            padding: 20px;
        }

        &__itemMainInfo {
            display: flex;
            display: flex;
            flex-wrap: wrap;
            grid-gap: 30px;
        }
    }
}

