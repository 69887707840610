@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:wght@300;400;500;600;700;800&family=Wix+Madefor+Display:wght@400;500;600;700;800&display=swap');

.home {
    font-family: 'Merriweather', serif;
    // font-family: 'Montserrat', sans-serif;
    // font-family: 'Wix Madefor Display', sans-serif;

    &__banner {
        padding-top: 120px;
        padding-bottom: 60px;
        background-image: url(../../../public/images/bgImage.jpeg);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__bannerBtn {
        font-family: 'Montserrat', sans-serif;
    }

    &__quote {
        background-image: url(../../../public/images/acro-back.jpeg);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        position: relative;
    }

    &__quoteOverlay {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        left: 0;
        opacity: 0.5;
        background-color: rgb(35, 35, 35);
    }

    &__videoWrapper {
        width: 100%;
        height: 400px;
    }

    .MuiSelect-select.MuiSelect-outlined {
        display: flex;
        font-size: 14px;
    }

    &__arcoPro {
        background-image: url(../../../public/images/homePageArcoPro.png);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        color: #fff;
    }

    &__arcoProOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background-color: rgb(89, 89, 89);
    }

    &__arcoTv {
        @extend .home__arcoPro;
        background-image: url(../../../public/images/homePageArcoTv.jpeg);
    }

    &__arcoTvOverlay {
        @extend .home__arcoProOverlay;
        opacity: 0.7;
        background-color: rgb(79, 76, 73);
    }

    &__followUs {
        color: #fff;
        background: linear-gradient(0deg, #9b5014, #9b4314);
    }

    &__contactUsBtn {
        font-family: 'Montserrat', sans-serif !important;
    }
}

.linkBtn {
    font-weight: 500;
    @apply transition;

    &:hover {
        @apply text-orange;
    }
}

.linkBtn--accent {
    @extend .linkBtn;
    font-weight: 700;
}

.homeMenu {
    font-family: 'Montserrat', sans-serif !important;
    padding: 10px 20px;
    background: transparent;
    position: fixed;
    top: 0;
    width: 100%;
    transition: .3s ease-in-out;
    z-index: 1;
}

.homeMenu--active {
    @extend .homeMenu;
    transition: .3s ease-in-out;
    background: #ffffff;
}

@media (max-width: 640px) {
    .home {
        &__banner {
            padding-top: 100px;
            padding-bottom: 0px;
        }

        &__videoWrapper {
            height: 250px;
        }

    }


    .homeMenu {
        padding: 5px 0px;

        &__mobileMenu {
            z-index: -10;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            background-color: #fff;
            transform-origin: top;
            transform: translateY(-100%);
            transition: transform 0.3s ease;
            transition: opacity 0.2s ease;
            padding: 20px;
            opacity: 0;

        }

        &__mobileMenu.open {
            transform: translateY(70px);
            opacity: 1;
        }


        &__burgerIcon {
            width: 25px;
            height: 30px;
            position: relative;
            cursor: pointer;

            div {
                width: 100%;
                height: 2px;
                background-color: #333;
                margin: 5px 0;
                border-radius: 3px;
                transition: transform 0.3s, opacity 0.3s;
            }

        }

        &__burgerIcon.open .bar1 {
            transform: rotate(-45deg) translate(-5px, 5px);
        }

        &__burgerIcon.open .bar2 {
            opacity: 0;
        }

        &__burgerIcon.open .bar3 {
            transform: rotate(45deg) translate(-5px, -5px);
        }
    }
}