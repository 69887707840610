@import '../../variables.scss';

.libraryHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__searchBtn {
        border-radius: 6px;
        border: 1px solid #DAE0E3;
        padding: 15px 30px;
        @apply text-blue;
        background: #F9FAFB;
        transition: $transition;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover {
            background: #fff;
        }
    }

    &__user {
        @apply flex items-center cursor-pointer text-blue;

        &:hover .libraryHeader__userName {
            @apply text-orange;
        }
    }

    &__userName {
        @apply font-bold transition;
    }

    &__userImg {
        @apply rounded-full w-10 h-10 flex items-center justify-center transition overflow-hidden mx-3 border border-transparent;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
        width: 220px;
        box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.12);
        border-radius: 12px;
        border: 1px solid #A3B0BB;
        background: #F9FAFB;
        padding: 10px 10px;
        z-index: 1000;
        
        li {
            @apply text-blue font-medium;
            padding: 5px 10px;
        }
    }

    .MuiPopper-root {
        transform: translate3d(0, 50px, 0px) !important;
    }
}


@media (max-width: 640px) {
    .libraryHeader { 
        &__user {
            @apply text-white;

            &:hover .libraryHeader__userName {
                @apply text-white;
            }
        }

        &__searchBtn {
            padding: 8px 15px;
        }
    }
}
