.message-list {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: var(--dark-background-color);
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* Space between messages */
}

