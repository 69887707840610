.pdf-container {
    width: 100%;
    max-width: 850px;
    margin-bottom: 20px;
    margin: 0 auto;
}

.react-pdf__Page {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
    transform-origin: 0 0;
}

.react-pdf__Page canvas {
    width: 100% !important;
    height: auto !important;
}

@media (max-width: 640px) {
    .pdf-container {
        margin-left: 0;
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
}