.assistant-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px; /* Adjust size as needed */
    height: 60px; /* Adjust size as needed */
    background: none; /* Remove background */
    border: none; /* Remove border */
    cursor: pointer;
    box-shadow: none; /* Remove shadow */
    transition: transform 0.2s ease;
}

.assistant-icon:hover {
    transform: scale(1.1); /* Scale effect on hover */
}

.assistant-icon img {
    width: 100%; /* Make the image fill the button */
    height: 100%; /* Make the image fill the button */
    border-radius: 0; /* No rounded corners */
    filter: hue-rotate(290deg); /* Adjust hue to blue */
}