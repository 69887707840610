.search {
    &__content {
        background: #fff;
        border-radius: 6px;
        padding: 0 0 20px 0;
        min-height: 100%;
    }

    &__title {
        @apply text-2xl font-semibold border-b border-lightGray;
        padding: 20px 30px;
    }

    &__items {
        padding: 20px 30px;
        display: grid;
        grid-template-columns: repeat(auto-fit, 330px);
        grid-gap: 20px;
    }

    &__item {
        background: #fff;
        border-radius: 10px;
        box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.18);
        padding: 15px;
        @apply border border-transparent transition;
        cursor: pointer;

        &:hover {
            @apply border border-lightBlue;
        }
    }

    &__difficulty {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: #eee;

        &:first-child {
            background: #A6EB99;
        }

        &:nth-child(2) {
            background: #F3A83B;
        }

        &:nth-child(3) {
            background: #F3A83B;
        }

        &:nth-child(4) {
            background: #EB3223;
        }

        &:nth-child(5) {
            background: #EB3223;
        }
    }
}

@media (max-width: 1100px) {

    .search {
        &__items {
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        }
    }
}


@media (max-width: 640px) {

    .search {
        &__content {
            background: none;
            padding: 0;
            margin-top: 20px;
        }

        &__items {
            padding: 0;
        }

        &__title {
            border: none;
            padding: 20px 0;
        }
    }
}