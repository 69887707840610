.googleBtn {
    border-radius: 5px;
    width: 100%;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: center;
    transition: .2s ease-in-out;
    @apply border border-lightGray text-blue;

    img {
       margin-right: 10px;
       width: 20px;
    }

    &:hover {
       @apply border-lightBlue;
       box-shadow: 0px 0px 10px #3070a447;
    }
 }