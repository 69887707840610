@import '../../variables.scss';

.btn {
    padding: 5px 20px;
    border-radius: 5px;
    transition: $transition;

    &--disabled {
        border: 1px solid #dddddd;
        color: #dddddd;
    }
}

.mainBlueButton {
    @extend .btn;
    @apply font-semibold text-white bg-blue py-3 px-5 w-full rounded bg-blue hover:bg-hoverBlue transition;

    &--disabled {
        @extend .mainBlueButton;
        pointer-events: none;
        background: #eee
    }
}

.blueButton {
    @extend .btn;
    @apply font-semibold text-white bg-blue py-2 px-10 w-full rounded bg-blue hover:bg-hoverBlue transition;

    &--disabled {
        @extend .blueButton;
        pointer-events: none;
        background: #eee
    }
}

.saveBtn {
    @extend .btn;
    border: 1px solid #75b76682;
    color: #75B766;

    &:hover {
        background: #75b7661b;
    }
}

.plainButton {
    @extend .btn;
    border: 1px solid #8e8e8e82;
    color: #424442;

    &:hover {
        background: #d3d3d31b;
    }
}

.cancelBtn {
    @extend .btn;
    border: 1px solid #d5010163;
    color: #D50201;

    &:hover {
        background: #d5010110;
    }
}

.accentBtn {
    @extend .btn;
    border: 1px solid #75c0db8f;
    color: #2D9DC7;

    &:hover {
        background: #75c0db14;
    }
}