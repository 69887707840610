.uploadImage {
   &__innerWrapper {
      @apply border bg-white  border-dashed border-lightBlue;
      border-radius: 24px;
      position: relative;
      overflow: hidden;
      margin: 0 auto;
      width: 100%;
      height: 320px;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   &__zoomSlider {
      width: 40% !important;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
   }
}

.reactEasyCrop_Container {
   height: 320px;

   .reactEasyCrop_CropArea {
      color: rgba(255, 255, 255, 0.704);
    }
}

// .reactEasyCrop_CropArea {
   // border: 2px solid red;
// }