.search {
    &__inputWrapper {
        border: 1px solid #DAE0E3;
        background: #ECEFF1;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        margin-top: 30px;
        cursor: pointer;

        &:hover {
            @apply border border-orange transition;
        }

        &:focus-within {
            @apply border border-orange transition;
        }

        input {
            width: 100%;
            padding: 5px 10px;
            background: transparent;
            outline: none;
            @apply text-dark font-medium;

            &::placeholder {
                @apply text-dark;
            }
        }
    }

    &__tags {
        margin-top: 10px;
        display: flex;
        grid-gap: 10px;
    }

    &__tag {
        padding: 3px 20px;
        @apply cursor-pointer relative bg-orange text-sm rounded-full text-dark font-medium border border-transparent transition;

        &:hover {
            @apply border border-lightGray;
        }

        &:hover .search__tagDeleteBtn {
            opacity: 1;
        }
    }

    &__tagDeleteBtn {
        position: absolute;
        opacity: 0;
        bottom: -12px;
        right: -8px;
        width: 23px;
        height: 23px;
        padding: 8px;
        background: #EB3223;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        border-radius: 50%;

    }
}

@media (max-width: 640px) {

    .search {
        &__inputWrapper {
            background: #fff;
            margin-top: 0px;
        }

        &__tags {
            grid-gap: 5px;
        }
    }
}