@import '../../variables.scss';

.libraryMenu {
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 20;
    
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        transition: $transition;
        pointer-events: none
    }

    &__overlay--active {
      @extend .libraryMenu__overlay;
      background: #23425c8b;
      pointer-events: initial;
      z-index: 2;
    }


    &__menuItems {
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 50px;
        left: 0;
        width: 100%;
        background-color: #dbdbdb;
        transform: translateY(-5%);
        transition: transform 0.3s ease-in-out;
        transition: .2s ease-in-out;
        opacity: 0;
        pointer-events: none;
        width: 100%;
        border-radius: 4px;
        z-index: 20;
        background: #fff;
        border-radius: 1px solid #A3B0BB;
        overflow: hidden;
        padding: 10px 0;
        box-shadow: rgba(0, 0, 0, .3) 0px 10px 15px;
    }
    &__menuItem {
        padding: 5px 20px;
        width: 100%;
        @apply text-blue;
        font-weight: 500;
        font-size: 15px;
        text-align: left;
        cursor: pointer;
        transition: $transition;

        &:hover {
            background: #a3b0bb35;
        }
    }


    &__folders {
        flex: 1 1 auto;
        height: 100px;
        overflow-y: auto;
        margin-top: 2px;

        &::-webkit-scrollbar-track {
            border-radius: 5px;
        }

        &::-webkit-scrollbar {
            width: 5px !important;
            background-color: #b8bee719 !important;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #5C7286 !important;
            border-radius: 5px;
            cursor: pointer !important;
        }
    }

    &__addPlaylistBtn {
        background: #f9fafb42;
        border-radius: 3px;
        padding: 5px;
        font-size: 30px;
        color: #fff;
        font-weight: 200;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: $transition;

        &:hover {
            background: #f9fafb54;
        }
    }

    &__menuBottomButtons {
        @apply bg-blue;
        display: flex;
        flex-direction: column;
        padding-top: 10px;
    }

    &__menuBottomBtn {
        border: 1px solid #f9fafb42;
        padding: 7px 10px;
        border-radius: 6px;
        margin: 0 auto;
        width: 100%;
    }

.hamburger-menuWrapper {
    width: 100%;
    position: relative;
}

.hamburger-iconWrapper {
    background: #ECEFF1;
    border-radius: 4px;
    padding: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: $transition;

    &:hover {
        background: #eceff1e8;
    }
}

/* Hamburger Icon Styles */
.hamburger-icon {
    display: flex;
    flex-direction: column;
    width: 23px;
    height: 17px;
    justify-content: space-between;
}

.bar {
    width: 20px;
    height: 2px;
    background-color: #23425C;
    border-radius: 2px;
}

/* Open state for the Hamburger Menu */
.hamburger-menuWrapper.open .bar:nth-child(1) {
    transform: translateY(7.5px) rotate(45deg);
}

.hamburger-menuWrapper.open .bar:nth-child(2) {
    opacity: 0;
}

.hamburger-menuWrapper.open .bar:nth-child(3) {
    transform: translateY(-7.5px) rotate(-45deg);
}


/* Open state for the Menu Items */
.hamburger-menuWrapper.open .libraryMenu__menuItems {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
}

&__listText {
    pointer-events: none !important;
}
}

@media (max-width: 640px) {
    .libraryMenu { 
        @apply bg-blue;
        width: 80%;
        height: 80vh;
        position: fixed;
        top: 0;
        right: -100%; 
        overflow-x: hidden;
        transition: 0.3s;
        z-index: 1;
        padding: 10px 20px !important;

        &--open {
            right: 0;
            box-shadow: #0c0d0fa6 0px 7px 29px 0px;
            z-index: 20;
        }

        &__menuBottomButtons {
            padding: 30px 0 !important;
        }
    }
}

   
