@import '../../../variables.scss';

.editProfile {
    &__imgWrapper {
        width: 130px;
        height: 130px;
        overflow: hidden;
        margin: 0 auto;
        border-radius: 5px;
        position: relative;
        
        &::after {
            content: "Update photo";
            font-size: 16px;
            @apply text-blue font-medium border border-lightBlue;
            line-height: 15px;
            padding: 20px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $transition;
            position: absolute;
            width: 100%;
            height: 100%;
            background: #ffffffa9;
            top: 0;
            left: 0;
            opacity: 0;
            border-radius: 5px;
        }
        
        &:hover::after {
            opacity: 1;
        }
    }
}


@media (max-width: 640px) {
    .editProfile {

        &__imgWrapper {
            border-radius: 50%;

            &:hover::after {
                opacity: 0;
            }

        }
    }
}

