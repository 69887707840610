@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Wix+Madefor+Display:wght@400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  font-family: 'Wix Madefor Display', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(to right top, #5876fd, #5795ff, #6cb1ff, #91caff, #bce1fe); */
  color: #183043;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiFormControl-root.MuiTextField-root {
  font-family: 'Montserrat', sans-serif !important;
  font-family: 'Wix Madefor Display', sans-serif !important;
}
