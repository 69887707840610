.searchMenu {
    &__item {
        padding: 5px 16px !important;
        cursor: pointer;
        border-radius: 5px;
        margin-bottom: 3px;

        .MuiListItemText-root {
            pointer-events: none !important;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.1);
        }

        &--active {
            @apply bg-lightBlue text-blue;
        }

    }

    &__item--active {
        @extend .searchMenu__item;
        @apply bg-lightBlue text-blue;

        &:hover {
            @apply bg-lightBlue text-blue;
        }
    }

    &__data {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
    }

    &__keywords {
        overflow-y: auto;
        margin-bottom: 30px;

        &::-webkit-scrollbar-track {
            border-radius: 5px;
        }

        &::-webkit-scrollbar {
            width: 5px !important;
            background: #b8bee719 !important;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: #5C7286 !important;
            border-radius: 5px;
            cursor: pointer !important;
        }

    }

    &__composers {
        width: 100%;
        margin-top: auto;
        flex: none;
        height: 22vh;
        overflow-y: auto;

        &::-webkit-scrollbar-track {
            border-radius: 5px;
        }

        &::-webkit-scrollbar {
            width: 5px !important;
            background: #b8bee719 !important;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: #5C7286 !important;
            border-radius: 5px;
            cursor: pointer !important;
        }

        .serachMenu__item {
            padding: 5px 16px !important;

            &:hover {
                background: rgba(255, 255, 255, 0.1);
                color: #fff;
            }
        }
    }

    &__composers--only {
        @extend .searchMenu__composers;
        flex: 1;
    }
    
    &__composers--min {
        height: auto;
    }

}